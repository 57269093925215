.jumbotron {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.bt-send {
    background-image: url("../images/send.png");
    background-size: cover;
    height: 5vh;
    width: 5vh;
    border: none;
    outline: none !important;
    background-color: transparent;
}
.topBtn {
    position: fixed;
    /* opacity: 0;  */
    bottom: 40px;
    right: 40px;
    z-index: 1 !important;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    border: 0 none;
    background-image: url("../images/top1.png");
    outline: 0;
    /* border: 2px solid blueviolet; */
    /* font-size: 18px; */
    /* font-weight: bold; */
    /* letter-spacing: -0.06em; */
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    /* cursor: pointer; */
    /* transition: opacity 0.3s ease-in; */
}
.topBtn:focus {
    border: none;
    outline: none;
}
.topBtn.active {
    /* z-index: 10;
    opacity: 0; */
background-color: black;
}
.topBtn:hover {
    border: 0 none;
    outline: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    background-image: url("../images/top2.png");
}

/* .topBtnImg:hover{} */

/* 가로 세로 텍스트위치 */
.CCC {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.LCL {
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: left;
}
.CBC {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding-bottom: 10vh !important;
}
.CTC {
    height: 100vh;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10vh !important;
}

/* background-img */
.bg-cover-home {
    background-image: url("../images/home.jpg");
    background-size: cover;
    border-radius: 0px !important;
    position: relative;
    z-index: 0;
}
.bt-store {
    background-image: url("../images/playStore.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    border: none;
    outline: none !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
}
.bg-cover-about {
    background-image: url("../images/about.jpg");
    /* background-color: black; */
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-feature-1 {
    background-image: url("../images/custom.jpg");
    /* background-color: black; */
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-feature-2 {
    background-image: url("../images/provisioning.jpg");
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-feature-3 {
    background-image: url("../images/security.jpg");
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-feature-4 {
    background-image: url("../images/deal.jpg");
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-service-1 {
    background-image: url("../images/service.jpg");
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-service-2 {
    background-image: url("../images/service2.jpg");
    background-size: cover;
    border-radius: 0px !important;
}
.bg-cover-contact {
    background-image: url("../images/contact.jpg");
    background-size: cover;
    border-radius: 0px !important;
}

/* text-font */
.font-light {
    font-family: "nanumSquare-light";
    justify-content: center;
}
.font-bold {
    font-family: "nanumSquare-bold";
}
.font-extra-bold {
    font-family: "nanumSquare-extra-bold";
}

/* text-font-color */
.text-red {
    color: #c62127 !important;
}
.text-white {
    color: #ffffff !important;
}
.text-gray {
    color: #bcbcbc !important;
}

/* text-style */
.container h2 {
    font-size: 40px;
    display: inline;
}
.container h4 {
    font-size: 18px;
    line-height: 1.44;
    font-weight: 300;
    font-stretch: normal;
    letter-spacing: normal;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* ------------------------------------------------ */
.slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
}

@keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@-webkit-keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@media screen and (max-width: 768px) {
    .about-content {
        width: 100% !important;
    }
    .container h2 {
        font-size: 28px;
    }
    .container h4 {
        font-size: 15px;
    }
    .col-sm-4 {
        text-align: center;
        margin: 25px 0;
    }
    .btn-lg {
        width: 100%;
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 480px) {
    .logo {
        font-size: 150px;
    }
}
@font-face {
    font-family: "nanumSquare-bold";
    src: url(../fonts/NanumSquareB.eot);
    src: url(../fonts/NanumSquareB.eot?#iefix)format("embedded-opentype");
    src: url(../fonts/NanumSquareB.woff)format("woff");
    src: url(../fonts/NanumSquareB.ttf)format("truetype");
}
@font-face {
    font-family: "nanumSquare-extra-bold";
    src: url(../fonts/NanumSquareEB.eot);
    src: url(../fonts/NanumSquareEB.eot)format("embedded-opentype");
    src: url(../fonts/NanumSquareEB.woff)format("woff");
    src: url(../fonts/NanumSquareEB.ttf)format("truetype");

}
@font-face {
    font-family: "nanumSquare-light";
    src: url(../fonts/NanumSquareL.eot);
    src: url(../fonts/NanumSquareL.eot?#iefix)format("embedded-opentype");
    src: url(../fonts/NanumSquareL.woff)format("woff");
    src: url(../fonts/NanumSquareL.ttf)format("truetype");
}