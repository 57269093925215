/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */

html {
    scroll-behavior: smooth;
}

.container {
    /*  background-color:#262626; */
    /* width: 100%!important; */
}
.navbar {
    padding: 0px !important;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    border-bottom: solid 1px #ebebeb;;
}

.navbar-brand {
    font-weight: bold;
    padding-left: 15px;
}

/* .nav-bg-blue {
  background-color: steelblue !important; 
}
 */

.nav-bg-white {
    background-color: white !important;
}

/* background-color: white !important;  */
.navbar-brand a {
    color: white;
    text-decoration: none;
}
.navbar-brand a:hover {
    color: aliceblue;
    text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */

/* .navbar-collapse a {
  color : black !important;
  padding : 20px !important;
  font-weight : 400!important;
  text-decoration : none!important;
}
.navbar-collapse a:hover, .navbar-collapse a:active {
  background-clip : padding-box!important; 
  border-bottom : 4.5px solid orange!important;
  color : white !important;
  background-color: rgb(15,43,44) !important;
  text-decoration : none!important;
} */

.nav-link {
    padding: 20px !important;
    font-weight: 400;
    color: black !important;
    border-bottom: 4.5px solid transparent;
}

.nav-link:hover,
.nav-link.active {
    display: block;
    text-decoration: none;
    /* text-transform: uppercase; */
    border-bottom: 4.5px solid #c42127;
    transition: all 0.3s ease-in-out;
}

.dropdown-item {
    color: gray !important;
}

.dropdown-item:hover,
.dropdown-item.active {
    color: skyblue !important;

    background-color: white !important;

    /* color: white;
  background-color: orange !important; */
}
.footer {
    width: 100%;
    padding: 30px 10%;
    margin: 10px 0 0;
    /* background-color: #333; */
    background-color: #262626;
    color: #ccc;
    font-family: "Roboto";
}
.footer h5 {
    font-size: 15px;
    line-height: 1.73;
    color: #ccc;
    letter-spacing: normal;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
  
}

.font-light {
    font-family: "nanumSquare-light";
    justify-content: center;
}
.font-bold {
    font-family: "nanumSquare-bold";
}

@font-face {
    font-family: "nanumSquare-bold";
    src: url(fonts/NanumSquareB.eot);
      /* IE-IE8 */
    src: url(fonts/NanumSquareB.eot?#iefix)format("embedded-opentype");
    /* Super Modern Browsers */
    src: url(fonts/NanumSquareB.woff)format("woff");
    /* Safari, Android, iOS */   
    src: url(fonts/NanumSquareB.ttf)format("truetype");
}

@font-face {
    font-family: "nanumSquare-extra-bold";
    src: url(fonts/NanumSquareEB.eot);
    src: url(fonts/NanumSquareEB.woff)format("woff");
    src: url(fonts/NanumSquareEB.eot)format("embedded-opentype");
    src: url(fonts/NanumSquareEB.ttf)format("truetype");
}

@font-face {
    font-family: "nanumSquare-light";
    src: url(fonts/NanumSquareL.eot);
    src: url(fonts/NanumSquareL.eot?#iefix)format("embedded-opentype");
    src: url(fonts/NanumSquareL.woff)format("woff");
    src: url(fonts/NanumSquareL.ttf)format("truetype");
}

@media screen and (min-width: 960px) {
    /* .navbar {
    padding: .5rem 10rem !important;
  } */
}
